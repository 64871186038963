import React, { useEffect, useRef } from 'react';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import "../CSS/Styles/ColorTheme.css";
import "../CSS/Pages/LandingPage.css";
import "../CSS/Components/ExpandableBox.css";
import "../CSS/Components/CarouselBoxAlignCenter.css";

function Carousel() {
    const swiperRef = useRef(null);
    const carouselRef = useRef(null);

    useEffect(() => {
        const swiper = new Swiper(swiperRef.current, {
            loop: false,
            pagination: {
                el: '.center-swiper-pagination',
                clickable: true,
                dynamicBullets: true,
            },
            spaceBetween: 30, // Space between slides
        });

        
        // Set up IntersectionObserver to detect when Swiper leaves view
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (!entry.isIntersecting) {
                    // When leaving the view, reset Swiper to the first slide
                    swiper.slideTo(0, 0); // Reset slide
                    swiper.update(); // Update swiper to ensure state consistency
                }
            },
            {
                root: null,
                threshold: 0.1, // Trigger when 10% is out of view
            }
        );
    
        if (carouselRef.current) {
            observer.observe(carouselRef.current);
        }
    
        // Cleanup function to disconnect the observer
        return () => {
            if (carouselRef.current) {
                observer.unobserve(carouselRef.current);
            }
            swiper.destroy();
        };
        
    }, []);

    return (
        <div className="center-carousel-wrapper" ref={carouselRef}>
            <div>
                <div className="center-swiper" ref={swiperRef}>
                    <div className="swiper-wrapper center-carousel-box">
                        <div className="swiper-slide center-swiper-slide primaryBody values-page">
                            <div className='center-carousel-content'>
                                <p className="quote" style={{ margin: '0' }}>
                                    <strong>PASSION</strong>
                                </p>
                                <p className="secondaryBody" style={{ height: '100%', alignContent: 'center'}}>
                                    It is our belief that thru <strong>deep love</strong> for the craft artists are <br />
                                    empowered to push boundaries of what’s possible in music, <br />
                                    film, and photography, to embrace  <strong>ambitious stories and ideas</strong> <br />
                                </p>
                            </div>
                        </div>
                        <div className="swiper-slide center-swiper-slide primaryBody">
                            <div className='center-carousel-content'>
                                <p className="quote" style={{ margin: '0' }}>
                                    <strong>AUTHENTICITY</strong>
                                </p>
                                <p className="secondaryBody"style={{ height: '100%' }}>
                                    In a world where overwhelming pressure pushes for conformity, <br />
                                    we stand firm in creating <strong>genuine work</strong> by <br />
                                    nurturing <strong>individuality</strong> and <br />
                                    safeguarding the essence of <strong>self-preservation,</strong>
                                </p>
                            </div>
                        </div>
                        <div className="swiper-slide center-swiper-slide primaryBody">
                            <div className='center-carousel-content'>
                                <p className="quote" style={{ margin: '0'  }}>
                                    <strong>CREATIVE FREEDOM</strong>
                                </p>
                                <p className="secondaryBody" style={{ height: '100%', alignContent: 'center' }}>
                                    We champion the boldness to take risks in our creative process.<br/><br/>
                                    We believe quality resides in a <strong>supportive community</strong> of creatives<br />
                                    who advocate for <strong>authentic expression</strong> to one's own identity
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="center-swiper-pagination"></div>
                </div>
            </div>
        </div>
    );
}

export default Carousel;
