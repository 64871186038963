// Footer.js
import React, { useEffect, useState } from 'react';

import '../CSS/Components/HeadLogo.css'; // Import the CSS file

// Import images from the src folder
import WhteYusefIcon from '../Images/YusefLogoIconWhte.svg';
import BlkYusefIcon from '../Images/YusefLogoIconBlk.svg';
import WhteYusefText from '../Images/YusefLogoTextWhte.svg';
import BlkYusefText from '../Images/YusefLogoTextBlk.svg';

function HeadLogo({ currentSection }) {
  // Example links with imported images
  const [textAnimationClass, setTextAnimationClass] = useState("");
  const [iconCentered, setIconCentered] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [iconVisible, setIconVisible] = useState(true);

  useEffect(() => {
    // Trigger the fade-in effect when the component mounts
    setIconVisible(true);
  }, []);

  const links =
    currentSection[2] === true ||
    currentSection[1] === "home" ||
    currentSection[0] === "landingVideo" ||
    currentSection[0] === "vision" ||
    currentSection[0] === "store" ||
    currentSection[0] === "about"
  ? [
      { id: "YusefText", src: WhteYusefText, url: "" },
      { id: "YusefLogo", src: WhteYusefIcon, url: "" }

    ]
  : [
      { id: "YusefText", src: BlkYusefText, url: "" },
      { id: "YusefLogo", src: BlkYusefIcon, url: "" },

    ];

  useEffect(() => {
    // if (mounted) {

      // Check if "video1" is the current section
      if (currentSection[0] === "video1") {
        setTextAnimationClass("fade-in");
        setIconCentered(false);
      } else {
        console.log("centering ICON");

        setTextAnimationClass("fade-out");
        setIconCentered(true)
        // setTimeout(() => , 500); // Center icon after text fades out
      }
      
      if (currentSection[0] !== "store" && currentSection[1] !== "about"){
        setIconVisible(true)
      }else{
        setIconVisible(false)
      }
    // }
  }, [currentSection, mounted]);

  useEffect(() => {
    // Prevent animation on initial render
    setMounted(true);
  }, []);
      
  return (
    <div>
      <div 
        className="homeLogo"         
        // style={{
        //   justifyContent: iconCentered ? "center" : "flex-start", // Dynamically align icon
        // }}
      >
        {links.map((link) => (
          <a
            key={link.id}
            className="yusefLogo"
            href={link.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {link.id === "YusefLogo" &&
              <img src={link.src} alt={link.id} className= {`${iconVisible ? "visible" : ""}`} style={{
                transform: iconCentered
                  ? "translateX(-100%) translateY(-20%)" // Slight adjustment when text is visible
                  : "translateX(-40%) translateY(-20%)",
              }}
              />}
            {link.id === "YusefText" && (
              <img
                src={link.src}
                alt={link.id}
                className={textAnimationClass} // Dynamically assign animation class
              />
            )}
          </a>
        ))}
      </div>
    </div>
  );
}

export default HeadLogo;


///// FORMAT FOR SOCIAL MEDIA ARRAY OF ICONS

const menuModes = [ 
  'home', 'why', 'vision', 'values', 'store', 'connect'
];


