import React, { useEffect, useRef } from 'react';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import "../CSS/Styles/Typography.css";
import "../CSS/Styles/ColorTheme.css";
import "../CSS/Pages/LandingPage.css";
import "../CSS/Components/ExpandableBox.css";
import "../CSS/Components/CarouselBoxAlignLeft.css";

function CarouselAlignLeft() {
    const swiperRef = useRef(null);
    const carouselRef = useRef(null);

    useEffect(() => {
        const swiper = new Swiper(swiperRef.current, {
            // loop: true,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                dynamicBullets: true,
            },
            spaceBetween: 30, // Space between slides
            centeredSlides: true,
        });

        // Set up IntersectionObserver to detect when Swiper leaves view
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (!entry.isIntersecting) {
                    // When leaving the view, reset Swiper to the first slide
                    swiper.slideTo(0, 0); // Reset slide
                    swiper.update(); // Update swiper to ensure state consistency
                }
            },
            {
                root: null,
                threshold: 0.1, // Trigger when 10% is out of view
            }
        );
  
        if (carouselRef.current) {
            observer.observe(carouselRef.current);
        }
  
        // Cleanup function to disconnect the observer
        return () => {
            if (carouselRef.current) {
                observer.unobserve(carouselRef.current);
            }
            swiper.destroy();
        };
    }, []);

    return (
        <div className="carousel-wrapper" ref={carouselRef}>
            <div className="swiper" ref={swiperRef}>
                <div className="swiper-wrapper">
                    <div className="swiper-slide carousel-content primaryBody">
                        <div className='carousel-content'>
                            <p className="quote">
                                "I am not just making music, <strong>I am creating an experience</strong>"
                                <br/>
                                <div className="author fineBody black">
                                    <h2  style={{ marginTop: '1rem' }}>-CARLON ROSALES</h2>
                                </div>
                            </p>
                            <p className="secondaryBody" style={{ marginBottom: '1rem', marginTop: '0rem'}}>
                                <strong>Our mission is to inspire faith</strong> in ambitious execution, challenging the pessimistic mindset
                                <br />
                                that visionary thinking is reserved only for those born with a supposed birthright of eminence.
                                <br /><br />
                                The exclusivity of <strong>greatness is a birthright of resilience!</strong>
                            </p>
                        </div>
                    </div>
                    <div className="swiper-slide carousel-content primaryBody">
                        <div className='carousel-content'>

                            <p className="secondaryBody" style={{ marginBottom: '1rem' }}>
                                By inspiring faith in <strong>ambitious execution</strong>, we nurture a mindset that empowers <br/>
                                individuals to believe in their <strong>potential,to overcome challenges,</strong> <br/>
                                and take proactive steps toward their goal. <br/><br/>

                                This belief fosters <strong>better mental and emotional health,</strong> 
                                <br/>
                                by encouraging strength, resilience, and a positive outlook in the face of adversity
                                
                            </p>
                        </div>
                    </div>
                    <div className="swiper-slide carousel-content primaryBody">
                        <div className='carousel-content'>

                            <p className="secondaryBody" style={{ marginBottom: '0rem'}}>
                                We believe in the <strong>power of collaboration</strong> and are always open to partnering <br/>
                                with creatives who share our vision of authenticity, innovation, and resilience. <br/>
                                <br/>
                                Together, we can <strong>create something truly transformative!</strong>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="swiper-pagination left-swpier"></div>
            </div>
        </div>
    );
}

export default CarouselAlignLeft;
