// Footer.js
import React from 'react';

import '../CSS/Components/Footer.css'; // Import the CSS file

// Import images from the src folder
import InstagramLogoWhite from '../Images/InstagramLogoWhite.png';
import YoutubeLogoWhite from '../Images/YoutubeLogoWhite.png';
import SpotifyLogoWhite from '../Images/SpotifyLogoWhite.png';
import AppleLogoWhite from '../Images/AppleLogoWhite.png';
import LinkLogoWhite from '../Images/LinkLogoWhite.png';

import InstagramLogoBlack from '../Images/InstagramLogoBlack.png';
import YoutubeLogoBlack from '../Images/YoutubeLogoBlack.png';
import SpotifyLogoBlack from '../Images/SpotifyLogoBlack.png';
import AppleLogoBlack from '../Images/AppleLogoBlack.png';
import LinkLogoBlack from '../Images/LinkLogoBlack.png';


function Footer({ currentSection }) {
  // Example links with imported images
const links = currentSection[2] === true|| currentSection[1] === 'home' || currentSection[0] === 'landingVideo' || currentSection[0] === 'vision' || currentSection[0] === 'store' || currentSection[0] === 'about'
  ? [
      { id: 'Instagram', src: InstagramLogoWhite, url: 'https://www.instagram.com/yusefagency/' },
      { id: 'Youtube', src: YoutubeLogoWhite, url: 'https://www.youtube.com/@yusefagency' },
      { id: 'Spotify', src: SpotifyLogoWhite, url: 'https://open.spotify.com/artist/02LFPcOSd5uMyzKtsgP0HP?si=bl58Ih02RQi5CXLv1pS1mw' },
      { id: 'Apple', src: AppleLogoWhite, url: 'https://music.apple.com/us/artist/c-lon/1734899255' },
    ]
  : [
      { id: 'Instagram', src: InstagramLogoBlack, url: 'https://www.instagram.com/yusefagency/' },
      { id: 'Youtube', src: YoutubeLogoBlack, url: 'https://www.youtube.com/@yusefagency' },
      { id: 'Spotify', src: SpotifyLogoBlack, url: 'https://open.spotify.com/artist/02LFPcOSd5uMyzKtsgP0HP?si=bl58Ih02RQi5CXLv1pS1mw' },
      { id: 'Apple', src: AppleLogoBlack, url: 'https://music.apple.com/us/artist/c-lon/1734899255' },
    ];

  return (
    <div>
      <div className='footer'>
      {links.map(link => (
        <a key={link.id} className= "socialsLink" href={link.url} target="_blank" rel="noopener noreferrer">
      
          <img src={link.src} alt={link.id} />
        </a>
      ))}
      </div>
  </div>
  );
}

export default Footer;


///// FORMAT FOR SOCIAL MEDIA ARRAY OF ICONS

const menuModes = [ 
  'home', 'why', 'vision', 'values', 'store', 'connect'
];


