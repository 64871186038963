import React, { useEffect, useState } from "react";
import "../CSS/Layouts/Connect.css";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase"; // Path to your firebase.js

function Connect() {
  const [email, setEmail] = useState("");

  // Handle input change
  const handleInputChange = (e) => {

    setEmail(e.target.value);
  };

  // Handle form submission
  const handleSubmit = async () => {
    if (!email) {
      alert("Please enter a valid email.");
      return;
    }

    try {
      // Add the email as a document with "getupdates" field set to true
      const docRef = doc(db, "users", email);
      await setDoc(docRef, { getupdates: true });
      alert("Successfully subscribed!");
      setEmail(""); // Clear the input field
    } catch (error) {
      console.error("Error adding email to Firestore:", error);
      alert("There was an error. Please try again.");
    }
  };


  return (
    <div id="connect-content">
      <div className="connect-cta">
        <div className="connect-title"><h1>STAY UPDATED...</h1></div>
        <div className="email-submission">
          <input
            type="email"
            className="email-box"
            placeholder="Email address"
            value={email}
            onChange={handleInputChange}
          />
          <button className="email-button" onClick={handleSubmit}>
            <h1 style={{ margin: '0' }}>SUBSCRIBE</h1>
          </button>
        </div>
      </div> {/* This closes connect-cta */}
      <div className="yosef-email" style={{ margin: '1vh' }}><h2>TEAM@YUSEF.AGENCY</h2></div>
      
    </div>
  );
}

export default Connect;
 