import React, { useEffect, useRef } from 'react';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import "../CSS/Styles/Typography.css";
import "../CSS/Styles/ColorTheme.css";
import "../CSS/Pages/LandingPage.css";
import "../CSS/Components/ExpandableBox.css";
import "../CSS/Components/CarouselBoxAlignLeft.css";

function ExpandedBox() {

    return (
        <div className="about-content">
            <h1 class="box-header black importantBody">WHY "YUSEF"?</h1>
            <div class="expandable-box">
            <div class="initial-content secondaryBody">
                <p class="box-summary">
                A religious reference to the historical figure <strong>JOSEPH</strong>{" "}
                <br></br>
                and the <strong>BIRTH NAME</strong> of{" "}
                <strong>CARLON ROSALES</strong>
                </p>
                <div class="box-content">
                <p style={{marginTop: "0", marginBottom: "0"}}>
                    <br></br>A narrative on{" "}
                    <strong>BURDEN, RESILIENCE & TRIUMPH</strong>
                </p>
                </div>
            </div>

            <div class="extra-content fineBody">
                <p>
                Yusuf's story is not just a personal reference but a{" "}
                <strong>universal symbol</strong>
                <br></br>
                of hope and the power of perseverance<br></br>
                His journey, marked by betrayal, adversity, and ultimate
                triumph, <br></br>
                <strong>resonates with anyone</strong> who has faced
                challenges and emerged <br></br>
                stronger. It serves as a <strong>beacon of hope</strong>,
                reminding us that even in the darkest <br></br>
                times, perseverance, patience, and faith can lead to success.
                <br></br>
                While rooted in a specific cultural context, the lessons from
                Yusuf's life are <br></br>
                universally relevant, making his narrative a{" "}
                <strong>source of inspiration</strong> <br></br>
                people from all walks of life...
                </p>
            </div>
            <h2 href="#" class="see-more-less">
                SEE MORE
            </h2>
            </div>
            <div class="box-footer fineBody black">
            <h2 style={ {marginBottom: 0}}>
                GOD WILL ADD
                <br />
                -YUSEF
            </h2>
            </div>
        </div>
    );
}

export default ExpandedBox;
