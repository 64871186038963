import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import LandingPage from '../src/Pages/LandingPage'; // Assuming default export
import UnderMaintenance from '../src/Pages/UnderMaintenance'; // Assuming default export
import { getPerformance } from "firebase/performance";

// import './App.css';
import './CSS/Pages/LandingPage.css';

// https://stackoverflow.com/questions/36904185/react-router-scroll-to-top-on-

function App() {

  console.log("App component rendered");
  useEffect(() => {
    const updateViewportHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };
  
    // Call on load
    updateViewportHeight();
  
    // Update on resize
    window.addEventListener("resize", updateViewportHeight);
  
    // Cleanup event listener
    return () => window.removeEventListener("resize", updateViewportHeight);
  }, []);
  
  return (
    <div className="App">
        <Router>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/comingsoon" element={<UnderMaintenance />} />
          </Routes>
        </Router>
    </div>
  );
}

export default App;
