import React, { useEffect, useState } from 'react';
import '../CSS/Styles/Typography.css';
import '../CSS/Styles/ColorTheme.css';
import '../CSS/Components/SideMenu.css';

function SideMenu({ currentSection, toggleMenu, isMenuOpen, lastVideoSection, videos}) {
  const menuItems = [
    { id: 'home', text: 'HOME' },
    { id: 'about', text: 'ABOUT' },
    { id: 'store', text: 'STORE' },
    { id: 'connect', text: 'CONNECT' }
  ];
  
  useEffect(() => {
    const sections = document.querySelectorAll('.menu-item'); // Use .menu-item to select by class
    const menuContainer = document.querySelector(".menu-container"); // Correct ID selection

    if (menuContainer) {
      console.log("Menu container found, running the code.");
      
      menuContainer.classList.remove("aboutMenu");
      console.log("ABOUITTTT");

      if (currentSection[1] === 'about') {
        menuContainer.classList.add("aboutMenu");
        console.log("is about");
      }
    } else {
      console.log("Menu container not found."); // Debugging output
    }

    sections.forEach((section) => {
      section.classList.remove('white', 'black'); // Remove both classes first

      if (isMobileDevice() === false) {

        if (currentSection[1] === 'home' || currentSection[0] === 'landingVideo' || currentSection[0] === 'vision' || currentSection[0] === 'store' || currentSection[0] === 'about') {
          section.classList.add('white');
          console.log("is white");
        } else {
          section.classList.add('black');
          console.log("is black");
        }
        console.log(`currentSection ${currentSection[0]}`);
      }else{
        section.classList.add('white');
        console.log("is phone menu");

      }
    });

    function isMobileDevice() {
      return window.innerWidth <= 768; // Adjust this width based on your needs
    }

    // Clean up function (if needed)
    return () => {
      sections.forEach((section) => {
        section.classList.remove('white', 'black'); // Remove classes on cleanup
      });

      if (menuContainer) {
        menuContainer.classList.remove(
          "aboutMenu",
        );
      }

    };
  }, [currentSection]); // Add currentSection as a dependency

  useEffect(() => {
    const hash = window.location.hash.slice(1); // Get the hash without the #
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }
  }, []);

  return (
    <h1 className={`menu-container ${isMenuOpen ? "open" : "closed"}`}>
      {menuItems.map(menuItem => (
        <a
          key={menuItem.id}
          href={`#${menuItem.id}`}
          onClick={(e) => {
            e.preventDefault(); // Prevent browser's default anchor scrolling
            toggleMenu();

            const targetSection = document.getElementById(menuItem.id);
            if (targetSection === "home"){
              videos[lastVideoSection].id.scrollIntoView({
                behavior: 'smooth',
                block: 'start', // Ensures scrolls to the top of the section
              }); 
            }else{
              if (targetSection) {
                targetSection.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start', // Ensures scrolls to the top of the section
                });
              } 
            }
            
          }}
          className={`menu-item ${currentSection[1]} ${currentSection[1] === menuItem.id ? 'active' : 'inactive'}`}
        >
          {menuItem.text}
        </a>
      ))}
    </h1>
  );
}

export default SideMenu;