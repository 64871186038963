import React, { useEffect, useState } from "react";
import "../CSS/Layouts/Connect.css";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase"; // Path to your firebase.js

function UnderMaintenance() {

  return (
    <div id="connect-content">
      <div className="connect-cta">
        <div className="connect-title">
          <a href="/" style={{fontSize:"3rem", color:"black", fontFamily:"nougat-script", textDecoration: 'none', color: 'white' }}>
            PAGE UNDER MAINTENANCE...
          </a>
        </div>
      </div>       
    </div>
  );
}

export default UnderMaintenance;
 