// firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCet53SAeTY9foAZH9fVNnMFeoQwkN4aQo",
    authDomain: "yusef-b9696.firebaseapp.com",
    projectId: "yusef-b9696",
    storageBucket: "yusef-b9696.firebasestorage.app",
    messagingSenderId: "555572873539",
    appId: "1:555572873539:web:d2dd288ff70314228cc7f6",
    measurementId: "G-GB5040JZJ9"
};
  

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
