import React, { useEffect, useRef, useState } from 'react';
import "../CSS/Layouts/Store.css";
import "../CSS/Styles/Typography.css"
import YosefArm from "../Images/YosefArm.png";
import YosefBody from "../Images/YosefBody.png";
import VinylCoverFront from "../Images/VinylCoverFront.png";
import Vinyl from "../Images/Vinyl.png";
import VinylCoverBack from "../Images/VinylCoverBack.png";
import NoDestinationLogo from "../Images/NoDestinationLogo.png";
import messageFromMa from "../Audio/Message from MA_.wav";
import myPromise from "../Audio/My Promise.wav";
import kingOutside from "../Audio/Kin(g) Outside.wav";
import egyptianChain from "../Audio/Roman Chains.wav";
import thisIsPromise from "../Audio/This is Promise_.wav";
import yosef from "../Audio/DAVID.wav";
import wokHard from "../Audio/Wok HARD.wav";
import hardLuv from "../Audio/HARD Luv.wav";
import assTalk from "../Audio/A$$ Talk.wav";

function Store() {

  const audioClips = [
    { id: 1, name: 'Message From Ma?', src: messageFromMa },
    { id: 2, name: 'My Promise!', src: myPromise },
    { id: 3, name: 'Kin G Outside', src: kingOutside },
    { id: 4, name: 'Egyptian Chain', src: egyptianChain },
    { id: 5, name: 'This is Promise?', src: thisIsPromise },
    { id: 6, name: 'Yusef', src: yosef },
    { id: 7, name: 'WOK Hard', src: wokHard },
    { id: 8, name: 'HARD LUV', src: hardLuv },
    { id: 9, name: 'A$$ TaLK', src: assTalk },
  ];

  const [currentTrackIndex, setCurrentTrackIndex] = useState(5); // Start at "Yosef"
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(new Audio(audioClips[5].src)); // Initialize with "Yosef" audio
  const storeRef = useRef(null);
  const [rotationDegrees, setRotationDegrees] = useState(0); // Store rotation state
  const [storeVisible, setStoreVisible] = useState(false);
  const [isRotating, setIsRotating] = useState(true); // For controlling rotation
  const diskRef = useRef(null); // Reference to the disk
  const animationRef = useRef(null); // Keep track of the animation frame
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Function to check screen size
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024); // For screens smaller than 768px
    };

    // Check screen size on mount
    handleResize();

    // Add event listener to track screen resize
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const playNextTrack = () => {
    if (currentTrackIndex < audioClips.length - 1) {
      const nextIndex = currentTrackIndex + 1;
      setCurrentTrackIndex(nextIndex);
      audioRef.current.src = audioClips[nextIndex].src;
      audioRef.current.play();
      startRotation();
    } else {
      stopRotation();
    }
  };

    // Play specific track when clicking on a track in the list
  const playTrack = (index) => {
    setCurrentTrackIndex(index);
    audioRef.current.src = audioClips[index].src;
    audioRef.current.play();
    startRotation();
  };
  
   // Update the rotation on every frame
   const updateRotation = () => {
    setRotationDegrees(prevDegrees => prevDegrees + 0.5); // Increment the rotation by 0.5 degrees per frame
  };


  const animateRotation = () => {
    if (isPlaying) {
      updateRotation();
      animationRef.current = requestAnimationFrame(animateRotation);
    }
  };
  
  const startRotation = () => {
    if (isPlaying) return;
    audioRef.current.play(); // Play the first track when the store is visible
    setIsPlaying(true);
    setIsRotating(true); // Set to true to enable rotation
    animationRef.current = requestAnimationFrame(animateRotation); // Keep the animation going
  };

  const stopRotation = () => {
    audioRef.current.pause(); // Stop the audio when leaving the store section
    setIsPlaying(false);
    setIsRotating(false); // Set to false to stop rotation
    cancelAnimationFrame(animationRef.current); // Stop the animation
  };

  useEffect(() => {
    // Set up intersection observer for animation triggers
    const storeSection = document.querySelector(".store-item");

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            storeSection.classList.add("in-view");
          } else {
            storeSection.classList.remove("in-view");
          }
        });
      },
      { threshold: 0.5 } // Trigger when 50% of the element is in view
    );

    observer.observe(storeSection);

    return () => {
      observer.disconnect();
    };
  }, []);

  // Handle auto-playing when user scrolls to the store section
  // Auto-play audio when user scrolls to the store section
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setStoreVisible(true);
          if (!isPlaying) {
            startRotation()
            console.log("Playing music...");
          }
        } else {
          setStoreVisible(false);
          if (isPlaying) {
            stopRotation();
            console.log("Stopping music...");
          }
        }
      },
      {
        root: null,
        threshold: 0.5, // Trigger when 50% of the store section is in view
      }
    );

  
    if (storeRef.current) {
      observer.observe(storeRef.current);
    }

    // Handle when the audio ends
    audioRef.current.addEventListener('ended', playNextTrack);

    return () => {
      if (storeRef.current) observer.unobserve(storeRef.current);
      audioRef.current.removeEventListener('ended', playNextTrack);
    };
  }, [storeVisible, currentTrackIndex]);


    const togglePlayPause = () => {
      if (isPlaying) {
        audioRef.current.pause();
        stopRotation();

      } else {
        audioRef.current.play();
        startRotation();

      }
    };

  return (
    <div id="store-content" ref={storeRef}>
      <div className="store-background">
        <img src={YosefBody} alt={`YosefBody`} />
        <img src={YosefArm} alt={`YosefArm`} />
      </div>
      <div id="store-overlay"></div> {/* Overlay added here */}

      <div className='storeContentBox'>

        <div className="item-title noDestinationTitle">
          <h1 style={{margin:0}} className="noDestinationTitle">Double sided 10 IN Vinyl</h1>
          <h2 className="noDestination" style={{ marginBottom: '0.5rem', fontSize: '2rem' }}>$32.00 USD</h2>
          <a style={{ opacity: '0.5' }}>LIMITED Pre-orders ship Feburary 2025</a>
        </div>

        <div className="store-item">
          <div className="vinyl-display">
            <div className="vinyl-front">
              <div className="vinyl-front-cover" onClick={togglePlayPause}>
                <img src={VinylCoverFront} alt={`VinylCoverFront`} />
              </div>
              <div ref={diskRef} className={`vinyl-front-disk ${isRotating ? 'vinyl-rotate' : ''}`}>
                <img src={Vinyl} alt={`Vinyl`} />
              </div>
            </div>
            <div className="vinyl-back-cover">
              <img src={VinylCoverBack} alt={`VinylCoverBack`} />
            </div>
          </div>

          {isMobile === false ? (

            <div className="track-list">
              <h1 style={{ margin: '0' }} className="noDestinationTitle">Track List</h1>
              <h2 style={{ margin: '0' }} className="track-content">
                {audioClips.map((clip, index) => (
                  <div >
                    {index === 0 ? (
                      <h2 className="noDestination">Side A</h2>
                    ) : null}

                    {index === 5 ? (
                      <h2 className="noDestination">Side B</h2>
                    ) : null}

                    <div
                    key={clip.id}
                    className={` noDestination track-item ${currentTrackIndex === index ? 'current' : ''}`}
                    onClick={() => playTrack(index)}
                    >
                      {index + 1}. {clip.name}
                    </div>
                  </div>
                ))}
              </h2>
            </div>
          ) : ( null)}

        </div>
        
        <div className="item-world">
        <div className="checkout-button">
          <a href="https://buy.stripe.com/eVa6qkef26nD8sUeUW" style={{color:"white", fontFamily:"nougat-script", textDecoration: 'none', fontWeight: 'bold', margin: "1rem", fontSize:"2.5rem"}} className="noDestinationTitle">Pre-Order</a>
        </div>
          <img src={NoDestinationLogo} alt={`NoDestinationLogo`} />
        </div>
      </div>

    </div>
  );
}

export default Store;
